import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { TimeTarget } from './event.model';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class TimeTargetService {

    private _timeTargets: BehaviorSubject<TimeTarget[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for timeTargets
     */
    get timeTargets$(): Observable<TimeTarget[]> {
        return this._timeTargets.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get timeTarget list 
     */

    getTimeTargetsInitial(): Observable<TimeTarget[]> {
        return this.getTimeTargets(null, null, null, null, null)
    }

    getTimeTargets(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<TimeTarget[]> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<TimeTarget[]>(`${BASE_URL}event/category/time_target/`, {
            params: {
                page: page ? ++page : 1,
                ordering: sort ? sort : '',
                search: query ? query : '',
                page_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                // this.pagination = {
                //     page: --response.page,
                //     total_count: response.total_count
                // };
                if (response?.length == 0)
                    this.errorMessage = "There are no time targets"
                this._timeTargets.next(response);
            })
        );
    }

    /**
     * Delete TimeTarget
     * @param timeTargetId
     */
    deleteTimeTarget(timeTargetId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}event/category/time_target/${timeTargetId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._timeTargets.next(this._timeTargets.value.filter(timeTarget => timeTarget.id !== timeTargetId));
            }, err => of([]))
        )
    }

    createTimeTarget(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}event/category/time_target/`, credentials);
    }

    updateTimeTarget(credentials, timeTargetId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}event/category/time_target/${timeTargetId}/`, credentials);
    }
}
